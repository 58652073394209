import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import land1 from '../assets/2.webp';
import land2 from '../assets/9.webp';
import land3 from '../assets/244-130Z2154Z8.jpg';
import land4 from '../assets/6.webp';
import land5 from '../assets/1.webp';
import land6 from '../assets/6a42254612117e1cf47fc37e926c0cbe02a09db352700-2zp9bf.jpeg';
import land7 from '../assets/244-130Z2154Z5.jpg';
import land8 from '../assets/4.webp';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Lands extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="conT2">LANDS</div>

                <div class="about2">

                    <div class="about3">
                        <img src={land1} />
                        <img src={land2} />
                    </div>

                    <div class="about3">
                        <img src={land4} />
                        <img src={land5} />
                    </div>

                    <div class="about3">
                        <img src={land6} />
                        <img src={land7} />
                    </div>

                    <div class="about3">
                        <img src={land3} />
                        <img src={land8} />
                    </div>

                </div>

            </div>
        )
    }
}

export default Lands;

