import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class GamePlay extends Component {

    render() {
        return (

            <div class="boxWrapPlay">
                <div class="conT2">THE GAMEPLAY</div>

                <div class="video-container">
                    <iframe src="https://www.youtube.com/embed/sJVegQq_axU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div class="playMain">
                    <div class="storyCon">
                        <p>collect resources</p>
                    </div>

                </div>

                <div class="playMain2">
                    <div class="storyCon">
                        <p>construct buildings</p>
                    </div>

                </div>

                <div class="playMain3">
                    <div class="storyCon">
                        <p>form alliances</p>
                    </div>

                </div>

                <div class="playMain4">
                    <div class="storyCon">
                        <p>raid outer lands</p>
                    </div>
                </div>

                <div class="playMain5">
                    <div class="storyCon">
                        <p>create the history and the myth</p>
                    </div>

                </div>

            </div>
        )
    }
}

export default GamePlay;

